var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    ref: "companyTable",
    staticClass: "table-taxInvoiceInbound",
    attrs: {
      "columns-data": _vm.header,
      data: _vm.rows,
      expandable: true,
      loading: _vm.loading,
      "row-length": _vm.rows.length,
      paginate: _vm.rows && _vm.rows.length > 25,
      "initial-sort": _vm.initialSort,
      "expandable-items": "filiais",
    },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function ({ column }) {
          return [
            column === "tree"
              ? _c("th", { staticClass: "pl-0 text-center w-0" })
              : _vm._e(),
          ]
        },
      },
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "tree" ? _c("td", { staticClass: "hidden" }) : _vm._e(),
            column === "nationalRegister"
              ? _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.formatCpfCnpj(item.nationalRegister)) + " "
                  ),
                ])
              : _vm._e(),
            column === "companyName"
              ? _c("td", [_vm._v(" " + _vm._s(item.companyName) + " ")])
              : _vm._e(),
            column === "certificate"
              ? _c("td", [
                  _vm._v(
                    " " + _vm._s(item.fileName ? item.fileName : "---") + " "
                  ),
                ])
              : _vm._e(),
            column === "taxInvoiceInbound"
              ? _c(
                  "td",
                  [
                    _vm._l(
                      item.taxInvoiceInbound,
                      function (inbound, idxBaixa) {
                        return _c("StatusInbound", {
                          key: idxBaixa,
                          attrs: {
                            type: inbound,
                            status: item.taxInvoiceInboundStatus,
                          },
                        })
                      }
                    ),
                    !item.taxInvoiceInbound.length
                      ? _c("StatusInbound", {
                          attrs: { type: "disabled", status: {} },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            column === "downloadAutomaticXml"
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(item.downloadAutomaticXml ? "XML" : "---") +
                      " "
                  ),
                ])
              : _vm._e(),
            column === null ? _c("td", { staticClass: "w-16" }) : _vm._e(),
          ]
        },
      },
      {
        key: "expandable",
        fn: function ({ item, classLine, index }) {
          return _vm._l(
            _vm.toTypeBranchArray(item.filiais),
            function (value, idx) {
              return _c(
                "tr",
                {
                  key: `row-expandable-${index}-${idx}`,
                  class: [classLine, "r-custom-table__row"],
                },
                [
                  _c("td", { staticClass: "hidden" }),
                  _c("td"),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatCpfCnpj(value.nationalRegister)) +
                        " "
                    ),
                  ]),
                  _c("td", [_vm._v(" " + _vm._s(value.companyName) + " ")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(value.fileName ? value.fileName : "---") +
                        " "
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _vm._l(
                        value.taxInvoiceInbound,
                        function (inbound, idxBaixa) {
                          return _c("StatusInbound", {
                            key: idxBaixa,
                            attrs: {
                              type: inbound,
                              status: value.taxInvoiceInboundStatus,
                            },
                          })
                        }
                      ),
                      !value.taxInvoiceInbound.length
                        ? _c("StatusInbound", {
                            attrs: { type: "disabled", status: {} },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(value.downloadAutomaticXml ? "XML" : "---") +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "w-16" }),
                ]
              )
            }
          )
        },
      },
      {
        key: "actions",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "actions" }, [
              item.status !== "desabilitado"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("edit"),
                          expression: "$t('edit')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.openInboundModal(item, true)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-edit" })]
                  )
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t(
                            "automaticTaxInvoiceInbound.companiesTable.enableInbound"
                          ),
                          expression:
                            "\n          $t('automaticTaxInvoiceInbound.companiesTable.enableInbound')\n        ",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.openInboundModal(item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-circle-accept" })]
                  ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }