
import mixins from 'vue-typed-mixins';
import FormatCpfCnpjMixin from '@/mixins/FormatCpfCnpjMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import RegisterModalTablePassword from '@/resources/automatic-tax-invoice-inbound/components/RegisterModalTablePassword.vue';
import {
  Branch, LegalSituation, MunicipalRegistrationChanged,
} from '@/types';
import { ENotasCities } from '@/common/services/admin-enotas/CitiesService';
import { mapMutations } from 'vuex';
import { Establishment, TaxInvoiceInbound } from '@roit-intern/roit-company-interfaces';
import { debounce } from 'lodash';
import StatusCompany from '@/resources/my-companies/components/StatusCompany.vue';

/* eslint-disable no-param-reassign */

export default mixins(FormatCpfCnpjMixin, NotificationMixin).extend({
  name: 'RegisterModalTable',
  components: {
    RegisterModalTablePassword,
    StatusCompany,
  },
  props: {
    establishments: {
      type: Array as () => Branch[],
      required: true,
    },
    municipalRegistrationChanged: {
      type: Array as () => MunicipalRegistrationChanged[],
      required: false,
    },
    certificates: {
      type: Array as () => Array<{ name: string; value: string }>,
      required: true,
    },
    headquartersNameNationalRegister: {
      type: String,
      required: true,
    },
    eNotasCities: {
      type: Array as () => Array<ENotasCities>,
      required: false,
    },
  },
  data() {
    return {
      initialSort: 'companyName',
      downloadAutomaticXml: false,
      showDefaultCertificate: false,
      legalSituationActive: LegalSituation.ACTIVE,
      allNFeIsSelected: false,
      allNFSeIsSelected: false,
      allCTeIsSelected: false,
      NFe: TaxInvoiceInbound.NFE,
      NFSe: TaxInvoiceInbound.NFSE,
      CTe: TaxInvoiceInbound.CTE,
      defaultCertificate: null as null | string,
      inputMaxWidth: 440,
      headerTable: {
        alias: [
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.branches',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.corporateName',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.legalSituation',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.municipalRegistration',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.certificate',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.NFe',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.NFSe',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.register.table.alias.CTe',
          ),
        ],
        columnsToShow: [
          'nationalRegister',
          'companyName',
          'legalSituation',
          'municipalRegistration',
          'fileName',
          'nfe',
          'nfse',
          'cte',
        ],
      },
    };
  },
  computed: {
    inputWidth(): number {
      return this.headquartersNameNationalRegister.length * 8;
    },
  },
  watch: {
    defaultCertificate(value: string): void {
      if (value === '') {
        this.allNFeIsSelected = false;
        this.allNFSeIsSelected = false;
        this.allCTeIsSelected = false;
      }
      if (value !== null) {
        this.establishments.forEach((establishment: Branch) => {
          if (establishment.legalSituation === LegalSituation.ACTIVE) {
            establishment.certificateId = value;
            if (value === '') {
              establishment.taxInvoiceInbound = [];
            }
          }
          return null;
        });
      }
    },
    downloadAutomaticXml(value: boolean): void {
      this.establishments.forEach((establishment) => {
        if (establishment.legalSituation === LegalSituation.ACTIVE) {
          establishment.downloadAutomaticXml = value;
        }
      });
    },
    establishments(): void {
      if (this.establishments.length > 0) {
        this.allNFeIsSelected = this.allEstablishmentsIncludesInbound(this.NFe);
        this.allNFSeIsSelected = this.allEstablishmentsIncludesInbound(this.NFSe);
        this.allCTeIsSelected = this.allEstablishmentsIncludesInbound(this.CTe);

        if (this.isDefaultCertificate()) {
          const { certificateId } = this.establishments[0];
          this.showDefaultCertificate = true;
          this.defaultCertificate = certificateId || '';
        }

        const headquarters = this.establishments.find((estab) => estab.type === 'MATRIZ');
        this.downloadAutomaticXml = headquarters?.downloadAutomaticXml || false;
      }
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    toogleSelectAllTaxInvoiceInbound(
      isSelected: boolean,
      type: TaxInvoiceInbound,
    ): void {
      if (isSelected) {
        this.establishments.forEach((establishment: Branch) => {
          const index = establishment.taxInvoiceInbound?.findIndex(
            (inbound: string) => inbound === type,
          );
          if (index !== -1 && index && establishment.taxInvoiceInbound) {
            establishment.taxInvoiceInbound.splice(index, 1);
          }
        });
      } else {
        this.establishments.forEach((establishment: Branch) => {
          const hasCertificate = establishment.certificateId;
          if (
            establishment.taxInvoiceInbound
            && !establishment.taxInvoiceInbound?.includes(type)
            && hasCertificate
            && establishment.legalSituation === LegalSituation.ACTIVE
          ) {
            establishment.taxInvoiceInbound.push(type);
          }
        });
      }

      if (type === this.NFe) {
        this.allNFeIsSelected = !this.allNFeIsSelected;
      } else if (type === this.NFSe) {
        this.allNFSeIsSelected = !this.allNFSeIsSelected;
      } else {
        this.allCTeIsSelected = !this.allCTeIsSelected;
      }
    },
    allEstablishmentsIncludesInbound(type: TaxInvoiceInbound): boolean {
      if (this.establishments.length === 0) { return false; }
      for (
        let establishment = 0;
        establishment < this.establishments.length;
        establishment += 1
      ) {
        if (!this.establishments[establishment]?.taxInvoiceInbound?.includes(type)) {
          return false;
        }
      }

      return true;
    },
    isDefaultCertificate(): boolean {
      if (this.establishments.length === 0) { return false; }
      const { certificateId } = this.establishments[0];

      if (certificateId === undefined) return false;

      for (
        let establishmentIdx = 0;
        establishmentIdx < this.establishments.length;
        establishmentIdx += 1
      ) {
        const establishment = this.establishments[establishmentIdx];
        if (
          establishment?.certificateId !== certificateId
          && establishment.legalSituation === LegalSituation.ACTIVE
        ) {
          return false;
        }
      }

      return true;
    },
    handleCertificate(item: Branch) {
      if (item.certificateId === '') {
        item.taxInvoiceInbound = [];
      }

      if (this.isDefaultCertificate()) {
        const { certificateId } = this.establishments[0];
        this.showDefaultCertificate = true;
        this.defaultCertificate = certificateId || '';
      } else {
        this.showDefaultCertificate = false;
        this.defaultCertificate = null;
      }
    },
    handleMunicipalRegistration: debounce(
      function handleMunicipalRegistrationDebounce(item: Establishment) {
        // @ts-ignore
        const estbIdx = this.municipalRegistrationChanged.findIndex((estb) => estb.id === item.id);

        if (estbIdx !== -1) {
          // @ts-ignore
          this.municipalRegistrationChanged[estbIdx].municipalRegistration = (
            item.municipalRegistration);
        } else {
          // @ts-ignore
          this.municipalRegistrationChanged.push({
            id: item.id as string,
            municipalRegistration: item.municipalRegistration,
          });
        }
      },
      300,
    ),
    municipalRegistrationRequired(item: Branch) {
      return item?.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)
             && !item.municipalRegistration;
    },
  },
});
