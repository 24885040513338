var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "caption" },
    _vm._l(_vm.captions, function (caption) {
      return _c(
        "div",
        { key: caption.label, staticClass: "caption__container" },
        [
          _c("div", { class: caption.type }),
          _c("span", [_vm._v(_vm._s(_vm.$t(caption.label)))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }