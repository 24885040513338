var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxWidth: "1200px", minWidth: "1200px" },
      attrs: {
        visible: _vm.displayModal,
        "content-style": { padding: "0", margin: "24px 1.125rem" },
        header: _vm.header,
        modal: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.displayModal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined p-button-raised",
                attrs: { id: "cancelButton", label: _vm.$t("cancel") },
                on: {
                  click: function ($event) {
                    _vm.displayModal = false
                  },
                },
              }),
              _c("Button", {
                staticClass: "raised next-step",
                attrs: {
                  id: "registerButton",
                  label: _vm.$t(`${_vm.editing ? "save" : "register"}`),
                },
                on: {
                  click: function ($event) {
                    return _vm.saveChanges()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("RegisterModalTable", {
        attrs: {
          establishments: _vm.establishments,
          certificates: _vm.certificatesOptions,
          "municipal-registration-changed": _vm.municipalRegistrationChanged,
          "e-notas-cities": _vm.eNotasCities,
          "headquarters-name-national-register":
            _vm.headquartersNameNationalRegister,
        },
        on: { updateENotas: _vm.updateENotasCities },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }