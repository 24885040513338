
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import StatusInbound from '@/resources/automatic-tax-invoice-inbound/components/StatusInbound.vue';
import FormatCpfCnpjMixin from '@/mixins/FormatCpfCnpjMixin';
import { Branch, EstablishmentTaxInvoiceInbound } from '@/types';

export default mixins(FormatCpfCnpjMixin).extend({
  name: 'CompaniesTable',
  components: {
    StatusInbound,
  },
  props: {
    rows: {
      type: Array as () => EstablishmentTaxInvoiceInbound[],
      required: true,
    },
    loading: {
      type: Boolean as () => boolean,
      default: false,
    } as PropOptions,
  },
  data() {
    return {
      initialSort: 'companyName',
      header: {
        alias: [
          'tree',
          this.$t('automaticTaxInvoiceInbound.companiesTable.alias.companies'),
          this.$t('automaticTaxInvoiceInbound.companiesTable.alias.corporateName'),
          this.$t('automaticTaxInvoiceInbound.companiesTable.alias.certificate'),
          this.$t(
            'automaticTaxInvoiceInbound.companiesTable.alias.taxInvoiceInbound',
          ),
          this.$t(
            'automaticTaxInvoiceInbound.companiesTable.alias.automaticScience',
          ),
          null,
        ],
        columnsToShow: [
          'tree',
          'nationalRegister',
          'companyName',
          'certificate',
          'taxInvoiceInbound',
          'downloadAutomaticXml',
          null,
        ],
      },
    };
  },
  methods: {
    openInboundModal(item: EstablishmentTaxInvoiceInbound, editing = false) {
      this.$emit('inbound:showRegisterModal', true, item, editing);
    },
    toTypeBranchArray(branchs: any[]): Branch[] {
      return branchs as Branch[];
    },
  },
});
