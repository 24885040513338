
import { CaptionInterface } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'Caption',
  props: {
    captions: {
      type: Array as () => CaptionInterface[],
      required: true,
    },
  },
});
