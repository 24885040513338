import axios from '@/common/http';
import { TaxInvoiceInboundPayload } from '@/types';

class TaxInvoiceInboundService {
  private service: typeof axios = axios;

  private baseURL = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/tax-invoice-inbound`;

  public taxInvoiceInboundAdministrator(payloads: TaxInvoiceInboundPayload[]) {
    return this.service.post(this.baseURL, payloads);
  }
}

export default new TaxInvoiceInboundService();
