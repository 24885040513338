
import mixins from 'vue-typed-mixins';
import FormatCpfCnpjMixin from '@/mixins/FormatCpfCnpjMixin';
import {
  Branch,
  DigitalCertificate,
  EstablishmentTaxInvoiceInbound,
  MunicipalRegistrationChanged,
} from '@/types';
import EstablishmentService from '@/common/services/EstablishmentService';
import { ENotasCities } from '@/common/services/admin-enotas/CitiesService';
import RegisterModalTable from './RegisterModalTable.vue';

export default mixins(FormatCpfCnpjMixin).extend({
  name: 'RegisterModalTaxInvoiceInbound',
  components: {
    RegisterModalTable,
  },
  props: {
    showModal: {
      type: Boolean as () => boolean,
      default: false,
    },
    company: {
      type: Object as () => EstablishmentTaxInvoiceInbound,
      required: true,
    },
    editing: {
      type: Boolean as () => boolean,
      default: false,
    },
    certificates: {
      type: Array as () => Array<DigitalCertificate>,
      required: true,
    },
    eNotasCities: {
      type: Array as () => Array<ENotasCities>,
      required: false,
    },
  },
  data() {
    return {
      certificatesOptions: [
        {
          name: this.$t('automaticTaxInvoiceInbound.register.emptyCertificate'),
          value: '',
        },
      ] as Array<{ name: string; value: string }>,
      establishments: [] as Branch[],
      municipalRegistrationChanged: [] as MunicipalRegistrationChanged[],
      headquartersNameNationalRegister: '',
      header: this.$t('automaticTaxInvoiceInbound.register.headerRegister'),
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean): void {
        this.$emit('update:showModal', value);
      },
    },
  },
  mounted(): void {
    if (this.editing) {
      this.header = this.$t('automaticTaxInvoiceInbound.register.headerEdit');
    }
    this.createEstablishmentsDeepCopy();
    this.buildCertificates();

    this.headquartersNameNationalRegister = `${
      this.company.companyName
    } - ${this.formatCpfCnpj(this.company.nationalRegister)}`;
  },
  methods: {
    createEstablishmentsDeepCopy(): void {
      const { filiais: branchs, ...headquarters } = this.company;

      const headquartersDeepCopy = JSON.parse(JSON.stringify(headquarters));
      const branchsDeepCopy = JSON.parse(JSON.stringify(branchs));
      this.establishments = [headquartersDeepCopy, ...branchsDeepCopy] as Branch[];
    },
    async saveChanges(): Promise<void> {
      const headquarters = this.establishments.find(
        (establishment) => establishment.type === 'MATRIZ',
      );

      const company = {
        ...headquarters,
        filiais: this.establishments.filter((establishment) => establishment.type !== 'MATRIZ'),
      } as EstablishmentTaxInvoiceInbound;

      if (this.municipalRegistrationChanged.length > 0
        && this.municipalRegistrationChanged.map((res) => res.municipalRegistration.length > 0)) {
        EstablishmentService.updateMunicipalRegistrationInBatch(
          this.municipalRegistrationChanged,
        );
      }
      this.$emit('save', company);
    },
    buildCertificates(): void {
      const { companyId } = this.company;

      this.certificates.forEach((certificate) => {
        const currentDate = new Date();
        const certificateValidate = new Date(certificate.validate as string);
        const certificateNotExpired = currentDate.getTime() < certificateValidate.getTime();

        if (certificate.companyId === companyId && certificateNotExpired) {
          this.certificatesOptions.push({
            name: certificate.fileName as string,
            value: certificate.id as string,
          });
        }
      });
    },
    updateENotasCities(newData: ENotasCities): void {
      this.$emit('updateENotas', newData);
    },
  },
});
