var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-4" }, [
        _c("p", { staticClass: "text-label family-Helvetica-Roman" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("automaticTaxInvoiceInbound.register.description")
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "flex gap-4" }, [
          _c("div", [
            _c("p", { staticClass: "m-0 text-label" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("automaticTaxInvoiceInbound.register.company")
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: {
                      value: _vm.headquartersNameNationalRegister,
                      disabled: _vm.inputWidth <= _vm.inputMaxWidth,
                    },
                    expression:
                      "{\n            value: headquartersNameNationalRegister,\n            disabled: inputWidth <= inputMaxWidth,\n          }",
                    modifiers: { top: true },
                  },
                ],
              },
              [
                _c("InputText", {
                  staticClass: "max-w-md",
                  style: { width: _vm.inputWidth + "px" },
                  attrs: { disabled: true },
                  model: {
                    value: _vm.headquartersNameNationalRegister,
                    callback: function ($$v) {
                      _vm.headquartersNameNationalRegister = $$v
                    },
                    expression: "headquartersNameNationalRegister",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex flex-col justify-around" }, [
            _c("p", { staticClass: "m-0 text-label" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "automaticTaxInvoiceInbound.register.defaultCertificate"
                    )
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "flex justify-between" },
              [
                _c("RadioButton", {
                  attrs: { id: "yes", value: true },
                  model: {
                    value: _vm.showDefaultCertificate,
                    callback: function ($$v) {
                      _vm.showDefaultCertificate = $$v
                    },
                    expression: "showDefaultCertificate",
                  },
                }),
                _c("label", { staticClass: "black", attrs: { for: "yes" } }, [
                  _vm._v(_vm._s(_vm.$t("yes"))),
                ]),
                _c("RadioButton", {
                  attrs: { id: "no", value: false },
                  model: {
                    value: _vm.showDefaultCertificate,
                    callback: function ($$v) {
                      _vm.showDefaultCertificate = $$v
                    },
                    expression: "showDefaultCertificate",
                  },
                }),
                _c("label", { staticClass: "black", attrs: { for: "no" } }, [
                  _vm._v(_vm._s(_vm.$t("no"))),
                ]),
              ],
              1
            ),
          ]),
          _vm.showDefaultCertificate
            ? _c(
                "div",
                {
                  staticClass: "flex items-end justify-center",
                  attrs: { id: "defaultCertificate" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t(
                          "automaticTaxInvoiceInbound.register.selectCertificate"
                        ),
                        size: "small",
                      },
                      model: {
                        value: _vm.defaultCertificate,
                        callback: function ($$v) {
                          _vm.defaultCertificate = $$v
                        },
                        expression: "defaultCertificate",
                      },
                    },
                    _vm._l(_vm.certificates, function (certificate, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: {
                          label: certificate.name,
                          value: certificate.value,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "flex flex-col justify-around" }, [
            _c("div", { staticClass: "h-6" }),
            _c(
              "div",
              { staticClass: "flex align-center" },
              [
                _c("Checkbox", {
                  attrs: { id: "xml", binary: true, value: true },
                  model: {
                    value: _vm.downloadAutomaticXml,
                    callback: function ($$v) {
                      _vm.downloadAutomaticXml = $$v
                    },
                    expression: "downloadAutomaticXml",
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "ml-2 text-label family-Helvetica-Roman",
                    attrs: { for: "xml" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("automaticTaxInvoiceInbound.register.xmls")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("custom-table", {
        ref: "companyTable",
        staticClass: "table-modal-taxInvoiceInbound",
        attrs: {
          "columns-data": _vm.headerTable,
          data: _vm.establishments,
          "row-length": _vm.establishments.length,
          "initial-sort": _vm.initialSort,
          "page-size-prop": _vm.establishments.length,
        },
        scopedSlots: _vm._u([
          {
            key: "heading",
            fn: function ({ column }) {
              return [
                [_vm.NFe, _vm.NFSe, _vm.CTe].includes(column)
                  ? _c("th", { staticClass: "pl-1" }, [
                      column === _vm.NFe
                        ? _c(
                            "div",
                            { staticClass: "gap-2" },
                            [
                              _c("Checkbox", {
                                attrs: { binary: true },
                                on: {
                                  click: function ($event) {
                                    return _vm.toogleSelectAllTaxInvoiceInbound(
                                      _vm.allNFeIsSelected,
                                      _vm.NFe
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.allNFeIsSelected,
                                  callback: function ($$v) {
                                    _vm.allNFeIsSelected = $$v
                                  },
                                  expression: "allNFeIsSelected",
                                },
                              }),
                              _vm._v(" " + _vm._s(column) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                      column === _vm.NFSe
                        ? _c(
                            "div",
                            { staticClass: "gap-2" },
                            [
                              _c("Checkbox", {
                                attrs: { binary: true },
                                on: {
                                  click: function ($event) {
                                    return _vm.toogleSelectAllTaxInvoiceInbound(
                                      _vm.allNFSeIsSelected,
                                      _vm.NFSe
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.allNFSeIsSelected,
                                  callback: function ($$v) {
                                    _vm.allNFSeIsSelected = $$v
                                  },
                                  expression: "allNFSeIsSelected",
                                },
                              }),
                              _vm._v(" " + _vm._s(column) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                      column === _vm.CTe
                        ? _c(
                            "div",
                            { staticClass: "gap-2" },
                            [
                              _c("Checkbox", {
                                attrs: { binary: true },
                                on: {
                                  click: function ($event) {
                                    return _vm.toogleSelectAllTaxInvoiceInbound(
                                      _vm.allCTeIsSelected,
                                      _vm.CTe
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.allCTeIsSelected,
                                  callback: function ($$v) {
                                    _vm.allCTeIsSelected = $$v
                                  },
                                  expression: "allCTeIsSelected",
                                },
                              }),
                              _vm._v(" " + _vm._s(column) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "columns",
            fn: function ({ item, column }) {
              return [
                column === "nationalRegister"
                  ? _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatCpfCnpj(item.nationalRegister)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                column === "companyName"
                  ? _c("td", [_vm._v(" " + _vm._s(item.companyName) + " ")])
                  : _vm._e(),
                column === "legalSituation"
                  ? _c(
                      "td",
                      [
                        _c("StatusCompany", {
                          attrs: { status: item.legalSituation },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                column === "municipalRegistration"
                  ? _c(
                      "td",
                      { staticClass: "w-44 municipal-registration" },
                      [
                        _c(
                          "ValidationObserver",
                          { ref: "form", attrs: { slim: "" } },
                          [
                            _c(
                              "input-validation",
                              {
                                attrs: {
                                  name: _vm.$t(
                                    "automaticTaxInvoiceInbound.register.table.alias.municipalRegistration"
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col relative" },
                                  [
                                    _c("InputText", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "automaticTaxInvoiceInbound.register.table.alias.municipalRegistration"
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.handleMunicipalRegistration(
                                            item
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.municipalRegistration,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "municipalRegistration",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.municipalRegistration",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                column === "fileName"
                  ? _c(
                      "td",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "automaticTaxInvoiceInbound.register.selectCertificate"
                              ),
                              size: "small",
                              disabled:
                                item.legalSituation !==
                                _vm.legalSituationActive,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleCertificate(item)
                              },
                            },
                            model: {
                              value: item.certificateId,
                              callback: function ($$v) {
                                _vm.$set(item, "certificateId", $$v)
                              },
                              expression: "item.certificateId",
                            },
                          },
                          _vm._l(_vm.certificates, function (certificate, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: {
                                label: certificate.name,
                                value: certificate.value,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                column === "nfe"
                  ? _c(
                      "td",
                      [
                        _c("Checkbox", {
                          attrs: {
                            value: _vm.NFe,
                            disabled:
                              !item.certificateId ||
                              item.legalSituation !== _vm.legalSituationActive,
                          },
                          on: {
                            change: function ($event) {
                              _vm.allNFeIsSelected =
                                _vm.allEstablishmentsIncludesInbound(_vm.NFe)
                            },
                          },
                          model: {
                            value: item.taxInvoiceInbound,
                            callback: function ($$v) {
                              _vm.$set(item, "taxInvoiceInbound", $$v)
                            },
                            expression: "item.taxInvoiceInbound",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                column === "nfse"
                  ? _c("td", [
                      _c(
                        "div",
                        { staticClass: "flex items-center gap-2" },
                        [
                          _c("Checkbox", {
                            attrs: {
                              value: _vm.NFSe,
                              disabled:
                                !item.certificateId ||
                                item.legalSituation !==
                                  _vm.legalSituationActive,
                            },
                            on: {
                              change: function ($event) {
                                _vm.allNFSeIsSelected =
                                  _vm.allEstablishmentsIncludesInbound(_vm.NFSe)
                              },
                            },
                            model: {
                              value: item.taxInvoiceInbound,
                              callback: function ($$v) {
                                _vm.$set(item, "taxInvoiceInbound", $$v)
                              },
                              expression: "item.taxInvoiceInbound",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                column === "cte"
                  ? _c(
                      "td",
                      [
                        _c("Checkbox", {
                          attrs: {
                            value: _vm.CTe,
                            disabled:
                              !item.certificateId ||
                              item.legalSituation !== _vm.legalSituationActive,
                          },
                          on: {
                            change: function ($event) {
                              _vm.allCTeIsSelected =
                                _vm.allEstablishmentsIncludesInbound(_vm.CTe)
                            },
                          },
                          model: {
                            value: item.taxInvoiceInbound,
                            callback: function ($$v) {
                              _vm.$set(item, "taxInvoiceInbound", $$v)
                            },
                            expression: "item.taxInvoiceInbound",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }