var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid pb-1" }, [
    _c("div", { staticClass: "row pt-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "header-container" },
          [
            _c("p", { staticClass: "grey300" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("automaticTaxInvoiceInbound.header.title")) +
                  " "
              ),
            ]),
            _c("Caption", { attrs: { captions: _vm.captions } }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row p-ai-end" }, [
      _c("div", { staticClass: "col-12 col-sm-12 col-md-3 ml-auto p-field" }, [
        _c(
          "span",
          { staticClass: "p-input-icon-left w-100" },
          [
            _c("i", { staticClass: "icon-r-magnifier-tool grey100" }),
            _c("InputText", {
              ref: "inputSearch",
              staticClass: "w-100 no-box",
              attrs: { placeholder: _vm.$t("search") },
              on: { input: _vm.searchDebounce },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }