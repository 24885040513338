
import Vue from 'vue';
import { Status, TaxInvoiceInbound, TaxInvoiceInboundStatus } from '@roit-intern/roit-company-interfaces';

export default Vue.extend({
  name: 'StatusInbound',
  props: {
    status: {
      // nfeConnection to legacy code
      type: Object as () => TaxInvoiceInboundStatus & {nfeConnection?: Status},
      default: undefined,
    },
    type: {
      type: String as () => TaxInvoiceInbound,
      required: true,
    },
  },
  computed: {
    getStatus(): string {
      const taxInvoiceInbound = 'taxInvoiceInbound ';
      if (
        this.type === TaxInvoiceInbound.CTE
        || this.type === TaxInvoiceInbound.NFE
      ) {
        return taxInvoiceInbound + Status.SUCCESS;
      }

      if (this.type === TaxInvoiceInbound.NFSE) {
        if (this.status?.roitInboundNFSe) {
          if (this.status?.roitInboundNFSe === Status.ERROR) {
            return taxInvoiceInbound + Status.ERROR;
          }

          if (this.status?.roitInboundNFSe === Status.PROCESSING) {
            return taxInvoiceInbound + Status.PROCESSING;
          }

          if (this.status?.roitInboundNFSe === Status.SUCCESS) {
            return taxInvoiceInbound + Status.SUCCESS;
          }
        } else {
          // this can be removed in the future when update BD to remove this status
          if (
            this.status?.eNotas === Status.ERROR
            || this.status?.nfeConnection === Status.ERROR
          ) {
            return taxInvoiceInbound + Status.ERROR;
          }

          if (
            this.status?.eNotas === Status.PROCESSING
            || this.status?.nfeConnection === Status.PROCESSING
          ) {
            return taxInvoiceInbound + Status.PROCESSING;
          }

          if (
            this.status?.eNotas === Status.SUCCESS
            && this.status?.nfeConnection === Status.SUCCESS
          ) {
            return taxInvoiceInbound + Status.SUCCESS;
          }
        }
      }

      return 'disabled';
    },
  },
});
