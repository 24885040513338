import axios from '@/common/http';
import { ENotasCreateOrUpdateDTO } from '@/types';

class ENotasService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_ENOTAS_URL}`;

  public async updateENotas(data: ENotasCreateOrUpdateDTO, identifier: string) {
    return this.service.patch(`${this.baseUrl}/${identifier}`, data);
  }

  public async createENotas(data: ENotasCreateOrUpdateDTO) {
    return this.service.post(`${this.baseUrl}`, data);
  }

  public async resync(identifier: string) {
    return this.service.post(`${this.baseUrl}/${identifier}/resync`);
  }
}

export default new ENotasService();
