var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "action", on: { click: _vm.toggleOp } },
    [
      _c("i", { staticClass: "icon-r-padlock-password" }),
      _c(
        "OverlayPanel",
        {
          ref: "op",
          staticClass: "op",
          attrs: { dismissable: true, "append-to": "body" },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col justify-start mb-3" },
            [
              _c("label", { attrs: { for: "supplierType" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "automaticTaxInvoiceInbound.register.table.passwordModal.supplierType.label"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("Dropdown", {
                attrs: {
                  id: "supplierType",
                  options: _vm.supplierTypeOption,
                  "option-label": "optionLabel",
                  "option-value": "optionValue",
                  placeholder: _vm.$t(
                    "automaticTaxInvoiceInbound.register.table.passwordModal.supplierType.placeholder"
                  ),
                  disabled: true,
                },
              }),
            ],
            1
          ),
          _c(
            "input-validation",
            {
              staticClass: "flex flex-col justify-start mb-3",
              attrs: {
                label: _vm.$t(
                  "automaticTaxInvoiceInbound.register.table.passwordModal.username"
                ),
                name: _vm.$t(
                  "automaticTaxInvoiceInbound.register.table.passwordModal.username"
                ),
              },
            },
            [
              _c(
                "span",
                { staticClass: "p-input-icon-left" },
                [
                  _c("i", { staticClass: "icon-r-user" }),
                  _c("InputText", {
                    staticClass: "w-full",
                    attrs: {
                      placeholder: _vm.$t(
                        "automaticTaxInvoiceInbound.register.table.passwordModal.username"
                      ),
                    },
                    model: {
                      value: _vm.username,
                      callback: function ($$v) {
                        _vm.username = $$v
                      },
                      expression: "username",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "input-validation",
            {
              staticClass: "flex flex-col justify-start mb-3",
              attrs: {
                label: _vm.$t(
                  "automaticTaxInvoiceInbound.register.table.passwordModal.password"
                ),
                name: _vm.$t(
                  "automaticTaxInvoiceInbound.register.table.passwordModal.password"
                ),
              },
            },
            [
              _c("InputPassword", {
                attrs: {
                  placeholder: _vm.$t(
                    "automaticTaxInvoiceInbound.register.table.passwordModal.password"
                  ),
                  autocomplete: "off",
                  "icon-left": true,
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex justify-end" },
            [
              _c("Button", {
                attrs: {
                  id: "registerButton",
                  label: _vm.$t(
                    "automaticTaxInvoiceInbound.register.table.passwordModal.saveBtnLabel"
                  ),
                },
                on: { click: _vm.handleSaveButton },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }