
import request from 'axios';
import InputPassword from '@/common/components/input-password/InputPassword.vue';
import AccessPassService from '@/common/services/AccessPassService';
import { AccessPass, AccessPass as AcessPassType } from '@/resources/access-pass/types';
import { Branch, ENotasCreateOrUpdateDTO } from '@/types';
import ENotasService from '@/common/services/ENotasService';
// eslint-disable-next-line import/extensions
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel';
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import { mapMutations } from 'vuex';

export default mixins(NotificationMixin).extend({
  name: 'RegisterModalTablePassword',
  components: { InputPassword },
  props: {
    establishment: {
      type: Object as () => Branch,
      required: true,
    },
    accessPasses: {
      type: Array as () => AccessPass[],
      required: true,
    },
  },
  data() {
    return {
      supplierTypeOption: [{
        optionLabel: 'Prefeitura',
        optionValue: 'CITY_HALL',
      }],
      username: '',
      password: '',
    };
  },
  watch: {
    accessPasses() {
      const accessPass = this.accessPasses.find(
        (data) => data.establishment && data.establishment.id === this.establishment.id,
      );

      this.username = accessPass?.username || '';
      this.password = accessPass?.password || '';
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    toggleOp(event: Event) {
      (this.$refs.op as OverlayPanel).toggle(event);
    },
    async handleSaveButton() {
      this.handleLoading(true);
      try {
        await Promise.all([
          this.createAcessPass(),
          this.createOrUpdateENotas(),
        ]);

        this.successToast({
          text: this.$t(
            'automaticTaxInvoiceInbound.register.table.updatePasswordSuccess',
          ) as string,
        });
      } catch {
        this.errorToast({
          text: this.$t(
            'automaticTaxInvoiceInbound.register.table.updatePasswordFail',
          ) as string,
        });
      }
      this.handleLoading(false);
      (this.$refs.op as OverlayPanel).hide();
    },
    async createAcessPass() {
      const payload: AcessPassType = {
        establishment: this.establishment,
        supplierType: 'CITY_HALL',
        supplier: {
          id: 'CITY_HALL',
          name: 'CITY_HALL',
        },
        username: this.username,
        password: this.password,
        state: {
          ibgeCode: this.establishment.address.ibgeCode,
          uf: this.establishment.address.state,
          name: this.establishment.address.state,
        },
        city: {
          ibgeCode: this.establishment.address.cityCode,
          name: this.establishment.address.city,
        },
      };
      await AccessPassService.create(payload);
    },
    async createOrUpdateENotas() {
      const eNotasPayload = {
        nfseHomologSettings: {
          userAccessProvider: this.username,
          passwordAccessProvider: this.password,
        },
        nfseProductionSettings: {
          userAccessProvider: this.username,
          passwordAccessProvider: this.password,
        },
      } as ENotasCreateOrUpdateDTO;

      try {
        await ENotasService.updateENotas(eNotasPayload, this.establishment.identifier);
        return;
      } catch (e) {
        if (request.isAxiosError(e) && e.response) {
          const { response: { data: { status, message } } } = e;

          if (status === 'failed' && message !== 'ENotas company register not found') {
            throw new Error();
          }
        }
      }

      try {
        await ENotasService.resync(this.establishment.identifier);
        await ENotasService.updateENotas(eNotasPayload, this.establishment.identifier);
        return;
      } catch (e) {
        if (request.isAxiosError(e) && e.response) {
          const { response: { data: { status, message } } } = e;

          if (status === 'failed'
              && (message !== 'ENotas company register not found' || message !== 'ENotas company integration not found')) {
            throw new Error();
          }
        }
      }

      eNotasPayload.identifier = this.establishment.identifier;

      await ENotasService.createENotas(eNotasPayload);
    },
  },
});
