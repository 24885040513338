import Vue from 'vue';

export default Vue.extend({
  name: 'FormatCpfCnpjMixin',
  methods: {
    formatCpfCnpj(value: string): string {
      if (value?.length <= 11) { // 11 refers to cpf length
        return value.replace(/\D/g, '')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      }

      if (value?.length <= 14) { // 14 refers to cpf length
        return value.replace(/\D/g, '')
          .replace(/^(\d{2})(\d)/, '$1.$2')
          .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2');
      }

      // If length doesn't match with CPF or CNPJ, return message
      return 'Invalid value';
    },
  },
});
