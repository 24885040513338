
import Vue from 'vue';
import { mapMutations } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import PageCard from '@/common/template/PageCard.vue';
import CompaniesTable from '@/resources/automatic-tax-invoice-inbound/components/CompaniesTable.vue';
import EstablishmentService from '@/common/services/EstablishmentService';
import {
  EstablishmentTaxInvoiceInbound,
  Group,
  TaxInvoiceInboundPayload,
  DigitalCertificate,
  UpdateCertificatesInBatchPayload,
} from '@/types';
import CertificateService from '@/common/services/CertificateService';
import TaxInvoiceInboundService from '@/common/services/TaxInvoiceInboundService';
import RegisterModalTaxInvoiceInbound from '@/resources/automatic-tax-invoice-inbound/components/RegisterModal.vue';
import HeaderSection from '@/resources/automatic-tax-invoice-inbound/components/sections/HeaderSection.vue';
import AutomaticTaxInvoiceInboundNotFound from '@/resources/automatic-tax-invoice-inbound/components/notice/AutomaticTaxInvoiceInboundNotFound.vue';
import { ENotasCities } from '@/common/services/admin-enotas/CitiesService';
import { Status, TaxInvoiceInbound } from '@roit-intern/roit-company-interfaces';

/* eslint-disable no-param-reassign */

export default Vue.extend({
  name: 'TaxInvoiceInbound',
  components: {
    PageCard,
    CompaniesTable,
    RegisterModalTaxInvoiceInbound,
    InfiniteLoading,
    HeaderSection,
    AutomaticTaxInvoiceInboundNotFound,
  },
  data() {
    return {
      groups: [] as Group[],
      loading: true as boolean,
      editing: false as boolean,
      showRegisterModal: false as boolean,
      company: {} as EstablishmentTaxInvoiceInbound,
      certificates: [] as DigitalCertificate[],
      page: 0,
      perPage: 25,
      search: '',
      eNotasCities: [] as ENotasCities[],
    };
  },
  computed: {
    showAutomaticTaxInvoiceInboundNotFound(): boolean {
      return this.loading || this.groups.length === 0;
    },
  },
  watch: {
    search() {
      this.page = 0;
      this.handleGetAutomaticTaxInvoiceInbound();
    },
  },
  async mounted() {
    this.handleGetAutomaticTaxInvoiceInbound();
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    toogleRegisterInboundModal(
      status: boolean,
      item: EstablishmentTaxInvoiceInbound,
      editing: boolean,
    ): void {
      this.company = item;
      this.showRegisterModal = status;
      this.editing = editing;
    },
    async saveEstablishmentChanges(changes: EstablishmentTaxInvoiceInbound): Promise<void> {
      this.handleLoading(true);
      let establishmentIdx;
      let groupIdx;
      let oldEstablishment = {} as EstablishmentTaxInvoiceInbound;
      for (let group = 0; group <= this.groups.length; group += 1) {
        establishmentIdx = this.groups[group].establishments.findIndex(
          (establishmentTaxInvoiceInbounds) => establishmentTaxInvoiceInbounds.nationalRegister
            === changes.nationalRegister,
        );

        if (establishmentIdx !== -1) {
          groupIdx = group;

          oldEstablishment = this.groups[group].establishments[establishmentIdx];
          this.groups[group].establishments.splice(
            establishmentIdx,
            1,
            changes,
          );
          break;
        }
      }

      if (establishmentIdx !== -1 && groupIdx !== -1) {
        await this.updateTaxInvoiceInbound(
          establishmentIdx as number,
          groupIdx as number,
          oldEstablishment,
        );

        await this.updateCertificatesInEstablishment(
          establishmentIdx as number,
          groupIdx as number,
          oldEstablishment,
        );
      }

      this.showRegisterModal = false;
      this.handleLoading(false);
    },
    async updateCertificatesInEstablishment(
      establishmentIdx: number,
      groupIdx: number,
      oldEstablishment: EstablishmentTaxInvoiceInbound,
    ) {
      const payload = [] as UpdateCertificatesInBatchPayload[];
      const headquarters = this.groups[groupIdx].establishments[establishmentIdx];

      const hasChangeInHeadquartersCertificate = (
        headquarters.certificateId !== oldEstablishment.certificateId
        && this.notIsFalsePositiveCertificateChange(
          oldEstablishment.certificateId,
          headquarters.certificateId,
        )
      );

      if (hasChangeInHeadquartersCertificate) {
        headquarters.fileName = this.certificates.find(
          (certificate) => certificate.id === headquarters.certificateId,
        )?.fileName || '';

        if (headquarters.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)) {
          headquarters.taxInvoiceInboundStatus = {
            roitInboundNFSe: Status.PROCESSING,
          };
        }

        payload.push({
          nationalRegister: headquarters.nationalRegister,
          certificateId: headquarters.certificateId || '',
          fileName: headquarters.fileName,
        });
      }

      headquarters.filiais.forEach((branchUpdated) => {
        const oldBranchData = oldEstablishment.filiais.find(
          (oldBranch) => oldBranch.nationalRegister === branchUpdated.nationalRegister,
        );

        const hasChangeInFilialCertificate = (
          oldBranchData?.certificateId !== branchUpdated.certificateId
          && this.notIsFalsePositiveCertificateChange(
            oldBranchData?.certificateId,
            branchUpdated.certificateId,
          )
        );

        if (hasChangeInFilialCertificate) {
          branchUpdated.fileName = this.certificates.find(
            (certificate) => certificate.id === branchUpdated.certificateId,
          )?.fileName || '';

          const isNfseRemoved = (
            !branchUpdated.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)
            && oldBranchData?.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)
          );
          const isNfseAdded = (
            branchUpdated.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)
            && !oldBranchData?.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)
          );

          if (isNfseRemoved || isNfseAdded) {
            branchUpdated.taxInvoiceInboundStatus = {
              roitInboundNFSe: Status.PROCESSING,
            };
          }

          payload.push({
            nationalRegister: branchUpdated.nationalRegister,
            certificateId: branchUpdated.certificateId || '',
            fileName: branchUpdated.fileName,
          });
        }
      });

      if (payload.length > 0) {
        EstablishmentService.updateCertificatesInBatch(payload);
      }
    },
    async updateTaxInvoiceInbound(
      establishmentIdx: number,
      groupIdx: number,
      oldEstablishment: EstablishmentTaxInvoiceInbound,
    ): Promise<void> {
      const payload = [] as TaxInvoiceInboundPayload[];

      const headquarters = this.groups[groupIdx].establishments[establishmentIdx];

      const hasChangesInHeadquartersTaxInvoiceInbound = !this.isEqualArray(
        headquarters.taxInvoiceInbound,
        oldEstablishment.taxInvoiceInbound,
      );

      const hasChangeInHeadquartersXml = (
        headquarters.fileName
        && headquarters.downloadAutomaticXml !== oldEstablishment.downloadAutomaticXml
        && this.notIsFalsePositiveAutomaticXmlChange(
          oldEstablishment.downloadAutomaticXml,
          headquarters.downloadAutomaticXml,
        )
      );

      const hasChangeInHeadquartersCertificate = (
        headquarters.certificateId !== oldEstablishment.certificateId
        && this.notIsFalsePositiveCertificateChange(
          oldEstablishment.certificateId,
          headquarters.certificateId,
        )
      );

      if (
        hasChangesInHeadquartersTaxInvoiceInbound
        || hasChangeInHeadquartersXml
        || hasChangeInHeadquartersCertificate
      ) {
        if (headquarters.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)) {
          headquarters.taxInvoiceInboundStatus = {
            roitInboundNFSe: Status.PROCESSING,
          };
        }

        payload.push({
          nationalRegister: headquarters.nationalRegister,
          taxInvoiceInbound: headquarters.taxInvoiceInbound || [],
          downloadAutomaticXml: headquarters.downloadAutomaticXml || false,
          identifier: headquarters.identifier,
          certificateId: headquarters.certificateId as string,
          municipalRegistration: headquarters.municipalRegistration,
        });
      }

      headquarters.filiais.forEach((branchUpdated) => {
        const oldBranchData = oldEstablishment.filiais.find(
          (oldBranch) => oldBranch.nationalRegister === branchUpdated.nationalRegister,
        );

        const hasChangesInFilialTaxInvoiceInbound = !this.isEqualArray(
          oldBranchData?.taxInvoiceInbound,
          branchUpdated.taxInvoiceInbound,
        );

        const hasChangeInFilialCertificate = (
          oldBranchData?.certificateId !== branchUpdated.certificateId
          && this.notIsFalsePositiveCertificateChange(
            oldBranchData?.certificateId as string,
            branchUpdated.certificateId,
          )
        );

        const hasChangeInFilialXml = (
          branchUpdated.fileName
          && branchUpdated.downloadAutomaticXml !== oldBranchData?.downloadAutomaticXml
          && this.notIsFalsePositiveAutomaticXmlChange(
            oldBranchData?.downloadAutomaticXml,
            branchUpdated.downloadAutomaticXml,
          )
        );

        if (
          hasChangesInFilialTaxInvoiceInbound
          || hasChangeInFilialXml
          || hasChangeInFilialCertificate
        ) {
          if (branchUpdated.taxInvoiceInbound?.includes(TaxInvoiceInbound.NFSE)) {
            branchUpdated.taxInvoiceInboundStatus = {
              roitInboundNFSe: Status.PROCESSING,
            };
          }

          payload.push({
            nationalRegister: branchUpdated.nationalRegister,
            taxInvoiceInbound: branchUpdated.taxInvoiceInbound || [],
            downloadAutomaticXml: headquarters.downloadAutomaticXml || false,
            identifier: branchUpdated.identifier,
            certificateId: branchUpdated.certificateId as string,
            municipalRegistration: branchUpdated.municipalRegistration,
          });
        }
      });

      if (payload.length > 0) {
        await TaxInvoiceInboundService.taxInvoiceInboundAdministrator(payload);
      }
    },
    isEqualArray(arr1: string[] | undefined, arr2: string[] | undefined): boolean {
      if (arr1 === undefined && arr2?.length === 0) return true;
      if (arr2 === undefined && arr1?.length === 0) return true;

      if (arr1?.length !== arr2?.length) return false;
      const arr1Copy = arr1 || [];
      const arr2Copy = arr2 || [];
      for (let idx = 0; idx < arr1Copy.length; idx += 1) {
        if (arr1Copy[idx] !== arr2Copy[idx]) return false;
      }

      return true;
    },
    async infiniteHandler($state: any) {
      let result;

      if (this.groups.length > 0) {
        result = (
          await EstablishmentService.findEstablishmentsGrouped('', this.page, this.perPage)
        ).data.data.filter(
          (res: { establishments: string | any[] }) => res.establishments.length > 0,
        );

        this.page += 1;
        const groups = this.completeGroupsData(result);
        this.groups.push(...groups);
      }

      if (result) {
        if (result.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    async handleGetAutomaticTaxInvoiceInbound() {
      this.handleLoading(true);

      const [{ data: { data } }, certificates] = await Promise.all([
        EstablishmentService.findEstablishmentsGrouped(this.search, this.page, this.perPage, 'tax-invoice-inbound'),
        CertificateService.findCertificates(),
      ]);

      this.page += 1;
      this.certificates = certificates;

      const groups = data.filter((group: Group) => group.establishments.length > 0);
      this.groups = this.completeGroupsData(groups);
      this.handleLoading(false);

      this.loading = false;
    },
    completeGroupsData(groups: Group[]) {
      const groupsCopy = JSON.parse(JSON.stringify(groups)) as Group[];
      return groupsCopy.map((group) => {
        group.establishments.forEach((estab) => {
          estab.taxInvoiceInbound = estab.taxInvoiceInbound ?? [];
          estab.municipalRegistration = estab.municipalRegistration ?? '';
          estab.downloadAutomaticXml = estab.downloadAutomaticXml ?? false;

          estab.filiais.forEach((branch) => {
            branch.taxInvoiceInbound = branch.taxInvoiceInbound ?? [];
            branch.municipalRegistration = branch.municipalRegistration ?? '';
            branch.downloadAutomaticXml = branch.downloadAutomaticXml ?? false;
          });
        });
        return group;
      });
    },
    updateENotasCities(newData: ENotasCities[]) {
      this.eNotasCities = newData;
    },
    notIsFalsePositiveCertificateChange(
      oldCertificateId: string | undefined,
      newCertificateId: string | undefined,
    ) {
      return !(oldCertificateId === undefined && newCertificateId === '');
    },
    notIsFalsePositiveAutomaticXmlChange(
      oldAutomaticXml: boolean | undefined,
      newAutomaticXml: boolean | undefined,
    ) {
      return !(oldAutomaticXml === undefined && newAutomaticXml === false);
    },
  },
});
