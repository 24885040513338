var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card rounded-lg mt-4", staticStyle: { height: "282px" } },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-items-center justify-content-center",
          staticStyle: { height: "100%", color: "#03181E54" },
        },
        [
          _c("div", [
            _c("img", {
              attrs: {
                src: require("@/common/assets/icons/icn_cadastrar_empresa.svg"),
                height: "106px",
                width: "117px",
              },
            }),
          ]),
          _c("div", { staticClass: "fs16" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("automaticTaxInvoiceInbound.notice.notFoundView")
                ) +
                " "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }