var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("PageCard", {
    staticClass: "my-companies-page",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("HeaderSection", {
              on: {
                "on:search": function ($event) {
                  _vm.search = $event
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "container-fluid pb-1" }, [
              _vm.showAutomaticTaxInvoiceInboundNotFound
                ? _c("div", [_c("AutomaticTaxInvoiceInboundNotFound")], 1)
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(_vm.groups, function (group, index) {
                    return _c(
                      "Accordion",
                      {
                        key: `group-${index}`,
                        staticClass: "accordion-taxInvoiceInbound mb-4 col-12",
                        attrs: { multiple: "" },
                      },
                      [
                        _c(
                          "AccordionTab",
                          {
                            attrs: { active: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(group.groupName) + " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("CompaniesTable", {
                              attrs: {
                                rows: group.establishments,
                                loading: _vm.loading,
                              },
                              on: {
                                "inbound:showRegisterModal":
                                  _vm.toogleRegisterInboundModal,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm.groups.length > 0
                    ? _c(
                        "infinite-loading",
                        {
                          staticClass: "mx-auto",
                          on: { infinite: _vm.infiniteHandler },
                        },
                        [
                          _c("span", {
                            attrs: { slot: "no-more" },
                            slot: "no-more",
                          }),
                          _c("span", {
                            attrs: { slot: "no-results" },
                            slot: "no-results",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.showRegisterModal
                    ? _c("RegisterModalTaxInvoiceInbound", {
                        attrs: {
                          editing: _vm.editing,
                          certificates: _vm.certificates,
                          company: _vm.company,
                          "e-notas-cities": _vm.eNotasCities,
                          "show-modal": _vm.showRegisterModal,
                        },
                        on: {
                          "update:showModal": function ($event) {
                            _vm.showRegisterModal = $event
                          },
                          "update:show-modal": function ($event) {
                            _vm.showRegisterModal = $event
                          },
                          updateENotas: _vm.updateENotasCities,
                          save: _vm.saveEstablishmentChanges,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }